
<template>
    <div class="stepsdemo-content">
        <Card class="py-10">
            <template v-slot:title>
              <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                Detallado de la tutela
              </h2>
            </template>
            <template v-slot:content>
              <div class="p-fluid p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
                <div class="p-fluid formgrid grid w-full">
                    <div class="grid">
                    <div class="flex flex-col w-full">
                        <label class="j-label">Numero de radicado</label>
                        <div class="grid grid-cols-1">
                        <div class="p-field">
                            <!-- <input type="text"
                                class="p-inputtext w-full"
                                v-model="model.numRadicado"
                            > -->
                            <InputMask mask="9999-99999" v-model="model.numRadicado" class="p-inputtext w-full" placeholder="0000-00000" />
                            <MessageError :text="errors.numRadicado"/>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="flex flex-col lg:grid lg:grid-cols-2 gap-4">
                    <div class="grid">
                        <div class="flex flex-col">
                        <label class="j-label">Fecha de auto</label>
                        <div class="grid grid-cols-1">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaExpedicion" >
                            <MessageError :text="errors.fechaExpedicion"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Recepción de la notificación</label>
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaRecepcion" >
                            <MessageError :text="errors.fechaRecepcion"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.horaRecepcion">
                            <MessageError :text="errors.horaRecepcion"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Vencimiento de terminos</label>
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaVencimiento" >
                            <MessageError :text="errors.fechaVencimiento"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.horaVencimiento">
                            <MessageError :text="errors.horaVencimiento"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Medida provisional</label>
                        <div class="flex mb-2">
                            <label class="j-label my-auto">no</label>
                            <InputSwitch class="mx-2" v-model="model.provisonalidad" />
                            <label class="j-label my-auto">si</label>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.provisonalidadFecha" >
                            <MessageError :text="errors.provisonalidadFecha"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.provisonalidadHora">
                            <MessageError :text="errors.provisonalidadHora"/>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="flex flex-col">
                            <label class="j-label">
                            Observaciones
                            </label>
                            <div class="p-field">
                                <textarea name="textarea"
                                        v-model="model.observaciones"
                                        class="p-inputtext w-full"
                                        rows="9"
                                        cols="50"/>
                                <MessageError :text="errors.observaciones"/>
                            </div>
                        </div>
                        <div class="mb-2">
                        <div class="w-full flex justify-between">
                            <div class="flex flex-col">
                            <label for="pathNotificacion" class="actual-btn">
                                <i class="pi pi-paperclip"/> Notificación
                            </label>
                            <input type="file" id="pathNotificacion" accept="application/pdf" hidden @change="uploaderNotificacion($event,'AUTO-VINCULA')"/>
                            <label>{{ nombre1 }}</label>
                            <MessageError :text="errors.pathNotificacion"/>
                            </div>
                            <div class="flex flex-col">
                            <label for="pathTutela" class="actual-btn">
                                <i class="pi pi-paperclip"/> Escrito tutela
                            </label>
                            <input type="file" id="pathTutela" accept="application/pdf" hidden @change="uploaderEscritoTutela($event,'TUTELA')"/>
                            <label>{{ nombre2 }}</label>
                            <MessageError :text="errors.pathTutela"/>
                            </div>
                        </div>
                        <MessageError :text="errors.soportesJuridicos" class="w-full"/>
                        </div>
                        <div class="flex flex-col">
                        <div class="p-field w-full">
                            <Dropdown class="w-full"
                                    :options="listUsuarios"
                                    option-label="fullName"
                                    option-value="id"
                                    placeholder="Seleccione responsable de la tutela"
                                    @change="setResponsable"
                                    v-model="model.userResponsableId"
                            />
                            <MessageError :text="errors.userResponsableId"/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
                <div class="flex w-full justify-center md:justify-between px-2">
                    <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Guardar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
              </div>
            </template>
        </Card>
    </div>
</template>

<script>
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import StoreUsuarios from '@/apps/pharmasan/juridica/procesosJuridicos/store/usuarios.store'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
export default {
  name: 'DatosAccionante',
  setup () {
    const router = useRouter()
    const toast = useToast()
    /** state **/
    const schema = yup.object({
      numRadicado: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaExpedicion: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaRecepcion: yup.string().nullable().required('El campo es obligatorio').label(''),
      horaRecepcion: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaVencimiento: yup.string().nullable().required('El campo es obligatorio').label(''),
      horaVencimiento: yup.string().nullable().required('El campo es obligatorio').label(''),
      observaciones: yup.string().nullable().required('El campo es obligatorio').label(''),
      userResponsableId: yup.number().nullable().required('El campo es obligatorio').label(''),
      soportesJuridicos: yup.array().of(
        yup.object().shape(
          {
            urlPath: yup.string().nullable().required('El campo es obligatorio').label(''),
            fileName: yup.string().nullable().required('El campo es obligatorio').label('')
          }
        )
      ).min(2, 'la notificion y el escrito tutela son obligatorios')
    })
    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('numRadicado', null, {
      initialValue: null
    })
    useField('fechaExpedicion', null, {
      initialValue: null
    })
    useField('fechaRecepcion', null, {
      initialValue: dayjs().format('YYYY-MM-DD')
    })
    useField('horaRecepcion', null, {
      initialValue: dayjs().format('hh:mm')
    })
    useField('fechaVencimiento', null, {
      initialValue: null
    })
    useField('horaVencimiento', null, {
      initialValue: null
    })
    useField('observaciones', null, {
      initialValue: null
    })
    useField('provisonalidad', null, {
      initialValue: false
    })
    useField('provisonalidadFecha', null, {
      initialValue: false
    })
    useField('provisonalidadHora', null, {
      initialValue: false
    })
    useField('userResponsableId', null, {
      initialValue: null
    })
    useField('soportesJuridicos', null, {
      initialValue: []
    })
    /** ref **/
    const nombre1 = ref('')
    const nombre2 = ref('')
    /** data **/
    /** computed **/
    const dataTutela = computed(() => {
      return storeTutela.getters._tutela
    })
    const listUsuarios = computed(() => StoreUsuarios.getters.usuarios)
    /** methodos **/
    const nextPage = handleSubmit((values) => {
        console.log('llego aqui')
      if (!values.provisonalidad) {
        delete values.provisonalidadFecha
        delete values.provisonalidadHora
      }
      storeTutela.commit('setDetalleTutela', values)
      storeTutela.dispatch('onSave').then(() => {
        toast.add({ severity: 'success', summary: 'Exíto', detail: 'registro exitoso', life: 3000 })
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.listado' })
        handleReset()
      })
    })
    const prevPage = () => {
      router.push('buscar-juzgado', { pageIndex: 1 })
    }
    const base64String = (f) => {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (() => {
          return function (e) {
            resolve(window.btoa(e.target.result))
          }
        })(f)
        reader.readAsBinaryString(f)
      })
    }
    const uploaderNotificacion = async (evt, fileName) => {
      const f = evt.target.files[0] // FileList object
      nombre1.value = f.name
      const index = model.soportesJuridicos.findIndex(e => e.fileName === fileName)
      if (index >= 0) {
        model.soportesJuridicos[index].urlPath = await base64String(f)
      } else {
        model.soportesJuridicos.push({
          urlPath: await base64String(f),
          fileName: 'AUTO-VINCULA'
        })
      }
    }
    const uploaderEscritoTutela = async (evt, fileName) => {
      const f = evt.target.files[0] // FileList object
      nombre2.value = f.name
      const index = model.soportesJuridicos.findIndex(e => e.fileName === fileName)
      if (index >= 0) {
        model.soportesJuridicos[index].urlPath = await base64String(f)
      } else {
        model.soportesJuridicos.push({
          urlPath: await base64String(f),
          fileName: 'TUTELA'
        })
      }
    }
    const setResponsable = ({ value }) => {
      storeTutela.commit('setResponsableId', value)
    }
    const resetTutela = () => {
      handleReset()
      storeTutela.commit('setReset')
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas' })
    }
    /** hook **/
    onMounted(() => {
      StoreUsuarios.dispatch('getAll')
      setTimeout(() => {
        model.numRadicado = dataTutela.value.numRadicado
        model.fechaExpedicion = dataTutela.value.fechaExpedicion
        model.fechaRecepcion = dataTutela.value.fechaRecepcion
        model.horaRecepcion = dataTutela.value.horaRecepcion
        model.fechaVencimiento = dataTutela.value.fechaVencimiento
        model.horaVencimiento = dataTutela.value.horaVencimiento
        model.observaciones = dataTutela.value.observaciones
        model.userResponsableId = dataTutela.value.userResponsableId
        nombre1.value = dataTutela.value.soportes[0].fileName
        nombre2.value = dataTutela.value.soportes[1].fileName
        model.soportesJuridicos = [
          {
            urlPath: dataTutela.value.soportes[0].urlPath,
            fileName: dataTutela.value.soportes[0].fileName
          },
          {
            urlPath: dataTutela.value.soportes[1].urlPath,
            fileName: dataTutela.value.soportes[1].fileName
          }
        ]
      }, 500)
    })
    return {
      dataTutela,
      errors,
      model,
      uploaderNotificacion,
      uploaderEscritoTutela,
      listUsuarios,
      setResponsable,
      resetTutela,
      nombre1,
      nombre2,
      nextPage,
      prevPage
    }
  }
  }
</script>
<style lang="scss" scoped>
 .j-label{
   color: #354357;
   font-size: 16px;
   opacity: 0.83;
 }
 .actual-btn {
   background-color: #1E40AF;
   color: white;
   padding: 0.5rem;
   font-family: sans-serif;
   border-radius: 0.3rem;
   cursor: pointer;
   width: 130px;
   //margin-top: 1rem;
 }
</style>
