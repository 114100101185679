import { createStore } from 'vuex'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'
const _UserSerivce = new UserSerivce()
const StoreUsuarios = {
  state: {
    _usuarios: []
  },
  getters: {
    usuarios: state => state._usuarios
  },
  mutations: {
    setUsuarios (state, payload) {
      state._usuarios = payload
    }
  },
  actions: {
    getAll ({ commit }) {
      _UserSerivce.getAll().then(({ data }) => {
        commit('setUsuarios', data)
      })
    }
  }
}
const store = createStore(StoreUsuarios)
export default {
  namespaced: true,
  ...store
}
